import logo from './logo.svg';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import { createRoot } from "react-dom/client";
import  Home from './views/Home/Home.js';
import Certification from './views/Certification/Certification.js';
import Quiz from './views/Quiz/Quiz.js';
import QuizResult from './views/QuizResult/QuizResult.js';
import Blog from './views/Blog/Blog.js';
import CertificationDetails from './views/CertificationDetails/CertificationDetails.js';
import Login from './views/Login/Login.js';
import BlogArticle from './views/BlogArticle/BlogArticle.js';
import Dashboard from './views/Dashboard/dashboard.js';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import './i18n.js';  // Import i18n configuration
import QuizByExam from './views/QuizByExam/QuizByExam.js';
import { useEffect } from 'react';
import { useLoading } from './LoadingContext';
import SplashScreen from './components/SplashScreen/SplashScreen.js';
import Contact from './views/Contact/Contact.js';
import NotFound from './views/NotFound/NotFound.js'; // Import the NotFound component
import QuizDashboard from './views/QuizDashboard/QuizDashboard.js';
import ReactGA from 'react-ga4';
import ExamQuizzes from './views/ExamQuizzes/ExamQuizzes.js';
import FeedbackForm from './views/FeedbackForm/FeedbackForm.js';
import TagManager from 'react-gtm-module';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home/>,
  },
  {
    path: "/certification",
    element: <Certification/>,
  },
  {
    path: "/quiz/:questionId",
    element: <Quiz/>,
  },
  {
    path: "/quiz-result/:questionId",
    element: <QuizResult/>
  },
  {
    path: "/quiz-result",
    element: <QuizResult/>,
  },
  {
    path: "/blog",
    element: <Blog/>
  },
  {
    path: '/blog-article/:slug',
    element: <BlogArticle/>
  },
  {
    path: '/certification/:id',
    element: <CertificationDetails/>,
  },
  {
    path: '/login',
    element: <Login/>
  },
  {
    path: '/dashboard',
    element: <Dashboard/>
  },
  {
    path: '/quiz-by-exam',
    element: <QuizByExam/>
  },
  {
    path: '/contact',
    element: <Contact/>
  },
  {
    path: '/exam-quizzes/:id',
    element: <ExamQuizzes/>
  },
  {
    path: '/quiz-dashboard',
    element: <QuizDashboard/>
  },
  {
    path:'/feedback',
    element: <FeedbackForm/>
  },
  {
    path: "*", // Wildcard route to match any undefined path
    element: <NotFound />
  }
]);

function App() {
  const { loading, setLoading } = useLoading();

  useEffect(() => {
    // Trigger loading state when the location changes (start of navigation)
    setLoading(true);

    const elements = document.querySelectorAll('.transition-element');
    elements.forEach((element) => {
      element.classList.add('transition-effect');
    });

    TagManager.initialize({ gtmId: 'GTM-TLSRJDTN' });  // Insira o seu GTM-ID

    // Initialize Google Analytics with your Measurement ID
    ReactGA.initialize('G-E1D7TCLNZ0');    
    // Send initial pageview
    ReactGA.send('pageview');

    // Mock a delay to simulate page load (e.g., fetching data)
    const timer = setTimeout(() => {
      // End of navigation (loading complete)
      setLoading(false);
    }, 1000); // Adjust the delay as needed to reflect actual loading times

    return () => clearTimeout(timer); // Clean up on component unmount
  }, [ setLoading]);
  
  return (
    <div className="App">
      {loading && <SplashScreen />}
      <RouterProvider router={router} />
    </div>
  );
}

export default App;