import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from "i18next-browser-languagedetector";
import backend from "i18next-http-backend";

const DETECTION_OPTIONS = {
  order: ['localStorage', 'navigator'],
  caches: ['localStorage']
};

i18n
  .use(detector)
  .use(backend) // Load translations from the backend
  .use(initReactI18next)
  .init({
    fallbackLng: 'en', // Default fallback language
    supportedLngs: ['en', 'es', 'fr', 'hi', 'pt'],
    ns: [
      'home',
      'contact',
      'certificationDetails',
      'examQuizzes',
      'quiz',
      'quizDashboard',
      'starRatingModal',
      'quizReview',
      'splashScreen',
      'blogHome'
    ],
    defaultNS: 'home', // Default namespace
    detection: DETECTION_OPTIONS,
    backend: {
      // Dynamically load translation files by page and language
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    interpolation: {
      escapeValue: false, // React handles escaping
    },
    react: {
      useSuspense: true, // Enables asynchronous loading
    },
  }
);
export default i18n;
