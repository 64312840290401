import logo from '../../assets/img/logo.png'; // Ajuste o caminho conforme a estrutura de arquivos
import './AppHeader.css';
import { useTranslation } from 'react-i18next';
import { Navbar, Nav, Container, Dropdown, Button } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import i18n from '../../i18n';

const languageOptions = {
  pt: { label: 'Português', flag: 'https://flagcdn.com/w20/br.png', alt: 'pt-BR' },
  en: { label: 'English', flag: 'https://flagcdn.com/w20/us.png', alt: 'en-US' },
  es: { label: 'Español', flag: 'https://flagcdn.com/w20/es.png', alt: 'es-ES' },
  fr: { label: 'Français', flag: 'https://flagcdn.com/w20/fr.png', alt: 'fr-FR' },
  hi: { label: 'हिंदी', flag: 'https://flagcdn.com/w20/in.png', alt: 'hi-IN' }
};


const AppHeader = () => {
  const { t } = useTranslation('navigation');  // Use the 'navigation' namespace
  const getLanguage = () => i18n.language || window.localStorage.i18nextLng;
  const [selectedLanguage, setSelectedLanguage] = useState(languageOptions[getLanguage.apply()]);

  // Load saved language from localStorage on component mount
  // useEffect(() => {
  //   // const savedLanguage = localStorage.getItem('language');
  //   // if (savedLanguage && languageOptions[savedLanguage]) {
  //   //   setSelectedLanguage(languageOptions[savedLanguage]);
  //   //   i18n.changeLanguage(savedLanguage);
  //   // }
  //   console.log("i18:" + i18n.language)
  //   setSelectedLanguage(languageOptions[i18n.language]);
  // }, [i18n]);

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setSelectedLanguage(languageOptions[lang]);
    window.location.reload();
  };

  return (
    <Navbar expand="lg" variant="dark" className="navbar">
      <Container>
        <Navbar.Brand href="/">
          <img src={logo} alt="Mock4Tech Logo" className="logo" style={{ height: 70 }} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto align-items-center">
            <Nav.Link 
              href="/" 
              className={`navElem ${window.location.pathname === '/' ? 'active' : ''}`}
            >
              {t('home')}
            </Nav.Link>

            <Nav.Link 
              href="/blog" 
              className={`navElem ${window.location.pathname === '/blog' ? 'active' : ''}`}
            >
              {t('blog')}
            </Nav.Link>

            {/* Contact Link */}
            <Nav.Link 
              href="/contact" 
              className={`navElem ${window.location.pathname === '/contact' ? 'active' : ''}`}
            >
              {t('contact')}
            </Nav.Link>

            {/* Language Selection Dropdown */}
            <Dropdown align="center">
              <Dropdown.Toggle className="noBgDropdown" id="dropdown-basic">
                <img
                  src={selectedLanguage.flag}
                  alt={selectedLanguage.alt}
                  style={{ marginRight: 8 }}
                />
                {selectedLanguage.label}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {Object.keys(languageOptions).map((langKey) => (
                  <Dropdown.Item key={langKey} onClick={() => changeLanguage(langKey)}>
                    <img
                      src={languageOptions[langKey].flag}
                      alt={languageOptions[langKey].alt}
                      style={{ marginRight: 8 }}
                    />
                    {languageOptions[langKey].label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            {/* Login Button */}
            {/* <Nav.Link href="/login">
              {!["/login", "/quiz-by-exam", "/quiz", "/quiz-result", "/quiz-dashboard"].includes(window.location.pathname) && (
                <Button className="loginButton">
                  <i className="bi bi-box-arrow-in-right"></i> {t('login')}
                </Button>
              )}
            </Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default AppHeader;


